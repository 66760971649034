<template>
  <div class="flex justify-content-center">
    <div class="chart-container">
      <div class="chart">
        <canvas style="width:400px; height:200px" ref="doughnutChart"></canvas>
      </div>
      <div class="legend">
        <div ref="legendContainer" class="mt-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "chart.js";

export default {
  name: "GraficoAnillo",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({ responsive: true, maintainAspectRatio: false }),
    },
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    chartData: "renderChart",
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.doughnutChart.getContext("2d");
      const chart = new Chart(ctx, {
        type: "doughnut",
        data: this.chartData,
        options: this.chartOptions,
      });

      // Inserta la leyenda personalizada
      const legendHtml = chart.generateLegend();
      this.$refs.legendContainer.innerHTML = legendHtml;
    },
  },
};
</script>

<style scoped>
.chart-container {
  display: flex;
  align-items: center;
  align-content: center;
  max-width: 1000px;
}

.chart-container .chart {
  flex: 1;
}

.chart-container .legend {
  width: 300px; /* Ajusta el ancho según sea necesario */
}
</style>
