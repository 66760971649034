import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;

const apilink = (params) => {
  const data = axios.get(PASARELA + "/reporte/peticiones", { params });

  return data;
};

export default { apilink };
