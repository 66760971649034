<template>
  <div data-app class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 ml-auto mr-auto">
          <div class="profile-tabs">
            <ul
              class="nav nav-pills nav-pills-icons justify-content-center"
              role="tablist"
            >
              <!-- DESCOMENTAR CUANDO SE DESEA AGREGAR OTRA SECCION  -->

              <!-- <li class="nav-item">
                <a
                  class="nav-link active show"
                  href="#apilink"
                  role="tab"
                  data-toggle="tab"
                  aria-selected="false"
                >
                  <i class="material-icons">format_color_text</i> APILINK
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- COMPONENTES (COMPONENTS/Transferencias) -->
      <div class="tab-content tab-space">
        <div class="tab-pane text-center gallery active show" id="apilink">
          <div class="row">
            <div class="col-md-12 ml-auto">
              <Apilink />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Apilink from "../../../components/WebServices/Apilink.vue";

export default {
  name: "Index",
  data() {
    return {
      items: ["Apilink"],
    };
  },
  components: { Apilink },
};
</script>

<style scoped>
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #00b1eb;
  box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(17 82 40 / 60%);
}
</style>
