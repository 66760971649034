<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <div class="card">
          <div class="card-header card-header-success">
            <h4 class="card-title">Peticiones Apilink</h4>
            <p class="card-category"></p>
          </div>

          <v-main>
            <v-container>
              <v-progress-circular
                :size="70"
                :width="7"
                color="green"
                indeterminate
                class="mt-5"
                v-if="loadingG"
              ></v-progress-circular>

              <div v-else-if="movimientos.length > 0">
                <GraficoAnillo
                  :chart-data="chartData"
                  :chart-options="chartOptions"
                />
              </div>

              <div v-else>
                <p class="text-gray-400">
                  Sin datos para mostrar.
                </p>
              </div>
            </v-container>
          </v-main>

          <section>
            <!-- filtros table  -->
            <v-expansion-panels v-model="panel">
              <v-expansion-panel :key="0">
                <v-expansion-panel-header
                  class="outline_none"
                  disable-icon-rotate
                >
                  <template v-slot:actions>
                    <span class="primary--text text-subtitle-1 mt-0"
                      >Filtros</span
                    >
                    <v-icon size="33" color="success"> search </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- 1 fila de filtros -->
                  <v-row class="pt-0 mx-5" align="center">
                    <v-col cols="6" md="3" lg="2">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        dark
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Fecha inicio"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            persistent-hint
                            hide-details
                            :value="formatDate(fechaInicio)"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaInicio"
                          locale="es-ar"
                          no-title
                          scrollable
                          :max="new Date().toISOString().substr(0, 10)"
                          @change="getData(), getDataGrafico()"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" md="3" lg="2">
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        dark
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Fecha fin"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            persistent-hint
                            hide-details
                            :value="formatDate(fechaFin)"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaFin"
                          locale="es-ar"
                          no-title
                          scrollable
                          :min="fechaInicio"
                          :max="new Date().toISOString().substr(0, 10)"
                          @change="getData(), getDataGrafico()"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- filtrar por Operacion -->
                    <v-col cols="6" md="3">
                      <v-autocomplete
                        v-model="operacion"
                        :items="[
                          'GetDebinesApiLink',
                          'CreateDebinApiLink',
                          'CreateTransferenciaApiLink',
                        ]"
                        clearable
                        label="Operación"
                        hide-details
                        @change="getData()"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6" class="mt-4" lg="5">
                      <v-alert
                        dense
                        elevation="3"
                        icon="mdi-counter"
                        outlined
                        prominent
                        text
                        type="success"
                        class="mx-5"
                        style="font-family: Montserrat, sans-serif; font-size: 1.2em;"
                      >
                        Cantidad de peticiones:
                        <v-progress-circular
                          indeterminate
                          color="green"
                          :size="20"
                          v-if="loadingG"
                        ></v-progress-circular>
                        <span v-else class="font-weight-bold">{{
                          total_de_peticiones ? total_de_peticiones : 0
                        }}</span>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!--end filtros-->
          </section>
          <v-skeleton-loader
            v-bind="attrs"
            type="table"
            v-if="loading"
          ></v-skeleton-loader>

          <div v-else>
            <v-data-table
              :headers="headers"
              :items="movimientos"
              hide-default-footer
              no-data-text="Sin datos para mostrar"
              loading-text="Cargando datos"
              :page.sync="page"
              :items-per-page="rowsPerPage"
              @page-count="pageCount = $event"
            >
              <template v-slot:[`item.fecha`]="{ item }">
                {{ formatDate(item.fecha) }}
              </template>
              <!-- show item.fecha with just hour and minutes -->
              <template v-slot:[`item.hora`]="{ item }">
                {{ item.fecha.split(" ")[1].split(".")[0] }}
              </template>
              <template v-slot:footer>
                <v-row class="no-gutters mt-2 mx-2">
                  <v-flex xs12>
                    <span>Mostrar</span>
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn dark text color="primary" class="ml-2" v-on="on">
                          {{ rowsPerPage }}
                          <v-icon>arrow_drop_down</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(number, index) in rowsPerPageArray"
                          :key="index"
                          @click="rowsPerPage = number"
                        >
                          <v-list-item-title>{{ number }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-flex>
                </v-row>
              </template>
            </v-data-table>

            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="20"
              ></v-pagination>
            </div>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import webservices from "../../services/webservices";
import formatDate from "../../utils/formatDate";
import moment from "moment";
import GraficoAnillo from "./GraficoAnillo.vue";

export default {
  name: "WebServiceApilink",
  components: {
    GraficoAnillo,
  },
  data() {
    return {
      panel: 0,
      loading: false,
      loadingG: false,
      menu1: false,
      menu2: false,
      fechaInicio: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      fechaFin: moment().format("YYYY-MM-DD"),
      operacion: "",

      headers: [
        { text: "Operación", sortable: false, value: "operacion" },
        { text: "Vendor", sortable: false, value: "vendor" },
        { text: "Fecha", sortable: false, value: "fecha" },
        { text: "Hora", sortable: false, value: "hora" },
      ],

      movimientos: [],
      totalesMovimientos: [],
      totales: [],
      total_de_peticiones: "",

      //paginacion
      page: 1,
      pageCount: 0,
      rowsPerPage: 30,

      rowsPerPageArray: [30, 50, 100],
      formatDate,

      chartData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          // position: "left",
          // align: "start",
        },
        title: {
          display: true,
          text: "Gráfico",
        },
        legendCallback: function(chart) {
          const data = chart.data.datasets[0].data;
          const labels = chart.data.labels;
          let legendHtml = "<ul>";

          labels.forEach((label, index) => {
            const value = data[index];
            const backgroundColor =
              chart.data.datasets[0].backgroundColor[index];
            legendHtml += `
      <li style="display: flex; align-items: start; margin-bottom: 5px;">
        <span style="background-color:${backgroundColor}; width:20px; height:10px; display:inline-block; margin-right:10px; margin-top:5px; border: ${backgroundColor} solid 1px"></span>
        ${label}: ${value}
      </li>
    `;
          });

          legendHtml += "</ul>";
          return legendHtml;
        },
      },
    };
  },

  mounted() {
    this.getDataGrafico();
    this.getData();
  },

  methods: {
    async getData() {
      const params = {
        FechaInicio: moment(this.fechaInicio).toISOString(),
        FechaFin: moment(this.fechaFin).toISOString(),
        Operacion: this.operacion ? this.operacion : "",
      };

      try {
        this.loading = true;
        this.menu1 = false;
        this.menu2 = false;
        const { data } = await webservices.apilink(params);
        this.movimientos = data?.result?.data || [];
        this.loading = false;
      } catch (err) {
        // console.error(err);
        this.loading = false;
        alert("error al comunicarse con el servidor");
      }
    },

    async getDataGrafico() {
      const params = {
        FechaInicio: moment(this.fechaInicio).toISOString(),
        FechaFin: moment(this.fechaFin).toISOString(),
        Operacion: "",
      };

      try {
        this.loadingG = true;
        this.menu1 = false;
        this.menu2 = false;
        const { data } = await webservices.apilink(params);
        const result = data?.result || {};
        this.total_de_peticiones = data?.result?.total_peticiones;
        this.formatChartData(result);
        this.loadingG = false;
      } catch (err) {
        console.error(err);
        this.loadingG = false;
        alert("error al comunicarse con el servidor");
      }
    },

    formatChartData(result) {
      const nombrePeticiones = {
        peticiones_create_debin: "Crear Debin",
        peticiones_create_transferencia: "Crear Transferencia",
        peticiones_debines: "Debines",
      };
      // Filtrar y organizar los datos según las claves en el orden deseado
      const peticionesData = Object.keys(nombrePeticiones).reduce(
        (acc, key) => {
          if (result[key] !== undefined) {
            acc[nombrePeticiones[key]] = result[key];
          }
          return acc;
        },
        {}
      );

      this.chartData = {
        labels: Object.keys(peticionesData),
        datasets: [
          {
            label: "Peticiones",
            data: Object.values(peticionesData),
            backgroundColor: [
              "rgba(255, 99, 132, 0.5)",
              "rgba(54, 162, 235, 0.6)",
              "rgba(255, 206, 86, 0.6)",
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
              "rgba(255, 99, 132, 0.6)",
              "rgba(54, 162, 235, 0.6)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
            ],

            borderWidth: 1,
          },
        ],
      };
    },
  },
};
</script>
